import React from 'react';
import { Link } from "gatsby"
import './menu.css';

let loaded = false;
export default function Menu() {
  return(
    <nav className="menu">
        <Link
          className={`menu-item ${loaded ? '' : 'menu-splash'}`}
          to="/speaking"
          style={{
            textDecoration: `none`,
          }}
        >
          Speaking
        </Link>

        <Link
          className={`menu-item ${loaded ? '' : 'menu-splash'}`}
          to="/writing"
          style={{
            textDecoration: `none`,
          }}
        >
          Writing
        </Link>
    </nav>
  )
}
